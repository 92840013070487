@font-face {
  font-family: 'Laser';
  src: url('../font/laser-Regular.ttf');
}

#viewerControls {
  z-index: 3;
  position: absolute;
  right: 25px;
  top: 18px;
  user-select: none;

  ul {
    list-style: none;
    padding: 0;
    float: right;
    margin-top: 18px;

    li {
      background-color: #04cc58;
      color: #dcdcdc;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      line-height: 36px;
      margin-bottom: 4px;
      text-align: center;
      transition: 0.3s;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
        background-color: #029942;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  #rotateCube.cube {
    width: 35px;
    height: 35px;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-300px);
    z-index: 2;

    .cube__face {
      position: absolute;
      width: 35px;
      height: 35px;
      border: 1px solid #949494;
      line-height: 35px;
      font-size: 12px;
      font-weight: bold;
      color: #949494;
      background-color: white;
      text-align: center;

      > span {
        position: absolute;
        transform-style: preserve-3d;
        transform: translateZ(5px);

        &:hover {
          opacity: 0.3;
          background-color: #6cc5ff;
          content: '';
          display: block;
          position: absolute;
          cursor: pointer;
        }
      }
    }
    .cube__faceplate {
      height: 20px;
      width: 20px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      opacity: 0;
    }
    .cube__facefront {
      transform: rotateY(0deg) rotateX(180deg) translateZ(-17px);
    }
    .cube__faceright {
      transform: rotateY(90deg) rotateX(180deg) translateZ(-17px);
    }
    .cube__faceback {
      transform: rotateY(180deg) rotateX(180deg) translateZ(-17px);
    }
    .cube__faceleft {
      transform: rotateY(-90deg) rotateX(180deg) translateZ(-17px);
    }
    .cube__facetop {
      transform: rotateX(-90deg) rotateX(180deg) translateZ(-17px);
    }
    .cube__facebottom {
      transform: rotateX(90deg) rotateX(180deg) translateZ(-17px);
      font-size: 8px;
    }
    #rotateCubeShadow {
      transform: rotateX(90deg) rotateX(180deg) translateZ(-19px);
      width: 29px;
      height: 29px;
      left: 0;
      right: 0;
      margin: auto;
      border: 0;
      background-color: rgba(71, 73, 74, 0.4);
    }

    .cube__face__side_top,
    .cube__face__side_bottom {
      width: 20px;
      height: 8px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .cube__face__side_left,
    .cube__face__side_back,
    .cube__face__side_front,
    .cube__face__side_right {
      width: 8px;
      height: 20px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
    .cube__face__side_top {
      top: -1px;
    }
    .cube__face__side_bottom {
      bottom: -1px;
    }
    .cube__face__side_left {
      left: -1px;
    }
    .cube__face__side_right {
      right: -1px;
    }
    .cube__face__side_back {
      right: -1px;
    }
    .cube__face__side_front {
      left: -1px;
    }

    #rotatecubeback .cube__face__side_right {
      right: auto;
      left: -1px;
    }
    #rotatecubeback .cube__face__side_left {
      left: auto;
      right: -1px;
    }
    #rotatecubeleft .cube__face__side_front {
      left: auto;
      right: -1px;
    }
    #rotatecubeleft .cube__face__side_back {
      right: auto;
      left: -1px;
    }
    #rotatecubetop .cube__face__side_back {
      bottom: auto;
      top: -1px;
      width: 20px;
      height: 8px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    #rotatecubetop .cube__face__side_front {
      top: auto;
      bottom: -1px;
      width: 20px;
      height: 8px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    #rotatecubebottom .cube__face__side_front {
      bottom: auto;
      top: -1px;
      width: 20px;
      height: 8px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    #rotatecubebottom .cube__face__side_back {
      top: auto;
      bottom: -1px;
      width: 20px;
      height: 8px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .cube__face__cornerTopRight,
    .cube__face__cornerBottomRight,
    .cube__face__cornerTopLeft,
    .cube__face__cornerBottomLeft,
    .cube__face__cornerTopFront,
    .cube__face__cornerBottomFront,
    .cube__face__cornerTopBack,
    .cube__face__cornerBottomBack,
    .cube__face__corner_left_back,
    .cube__face__corner_left_front,
    .cube__face__corner_right_back,
    .cube__face__corner_right_front {
      width: 8px;
      height: 8px;
    }
    .cube__face__cornerTopRight,
    .cube__face__cornerTopLeft,
    .cube__face__cornerTopFront,
    .cube__face__cornerTopBack,
    .cube__face__corner_left_back,
    .cube__face__corner_right_back {
      top: 0;
    }
    .cube__face__cornerBottomRight,
    .cube__face__cornerBottomLeft,
    .cube__face__cornerBottomFront,
    .cube__face__cornerBottomBack,
    .cube__face__corner_left_front,
    .cube__face__corner_right_front {
      bottom: 0;
    }
    .cube__face__cornerBottomLeft,
    .cube__face__cornerTopLeft,
    .cube__face__cornerTopFront,
    .cube__face__cornerBottomFront,
    .cube__face__corner_left_back,
    .cube__face__corner_left_front {
      left: 0;
    }
    .cube__face__cornerTopRight,
    .cube__face__cornerBottomRight,
    .cube__face__cornerTopBack,
    .cube__face__cornerBottomBack,
    .cube__face__corner_right_back,
    .cube__face__corner_right_front {
      right: 0;
    }
    #rotatecubeback .cube__face__cornerBottomLeft,
    #rotatecubeback .cube__face__cornerTopLeft,
    #rotatecubeleft .cube__face__cornerBottomFront,
    #rotatecubeleft .cube__face__cornerTopFront {
      right: 0;
      left: auto;
    }
    #rotatecubeback .cube__face__cornerTopRight,
    #rotatecubeback .cube__face__cornerBottomRight,
    #rotatecubeleft .cube__face__cornerTopBack,
    #rotatecubeleft .cube__face__cornerBottomBack {
      left: 0;
      right: auto;
    }
    #rotatecubebottom .cube__face__corner_left_front,
    #rotatecubebottom .cube__face__corner_right_front {
      top: 0;
      bottom: auto;
    }
    #rotatecubebottom .cube__face__corner_left_back,
    #rotatecubebottom .cube__face__corner_right_back {
      bottom: 0;
      top: auto;
    }
  }

  #rotateCubeArrows {
    position: absolute;
    top: 0;
    width: 35px;
    height: 35px;
    z-index: 1;

    .arrow {
      width: 0;
      height: 0;
      position: absolute;
      cursor: pointer;
    }

    #arrowUp {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-bottom: 5px solid #04cc58;
      bottom: -8px;
      left: 0;
      right: 0;
      margin: 0 auto;

      &:hover {
        border-bottom-color: lighten(#04cc58, 20%);
      }
    }
    #arrowDown {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-top: 5px solid #04cc58;
      top: -8px;
      left: 0;
      right: 0;
      margin: 0 auto;

      &:hover {
        border-top-color: lighten(#04cc58, 20%);
      }
    }
    #arrowRight {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;

      border-left: 5px solid #04cc58;
      left: -8px;
      top: 0;
      bottom: 0;
      margin: auto 0;

      &:hover {
        border-left-color: lighten(#04cc58, 20%);
      }
    }
    #arrowLeft {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;

      border-right: 5px solid #04cc58;
      right: -8px;
      top: 0;
      bottom: 0;
      margin: auto 0;

      &:hover {
        border-right-color: lighten(#04cc58, 20%);
      }
    }
  }
}

#qrWrapper {
  #qrScanner {
  }
  #spinner {
    position: absolute;
    right: 22px;
    color: #029942; //var(--primary);
  }
}

#d-pad {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 48%;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 5%;
    transform: translate(-50%, -50%);
    width: 66.6%;
    height: 66.6%;
    background: #ddd;
  }
  &:after {
    content: '';
    position: absolute;
    display: none;
    z-index: 2;
    width: 20%;
    height: 20%;
    top: 50%;
    left: 50%;
    background: #ddd;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.25s;
    cursor: pointer;
  }
  &:hover:after {
    width: 30%;
    height: 30%;
  }

  span {
    display: block;
    position: absolute;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    width: 33.3%;
    height: 43%;
    line-height: 40%;
    color: #fff;
    background: #ddd;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-radius: 5px;
      border-style: solid;
      transition: all 0.25s;
    }
    &:after {
      content: '';
      position: absolute;
      width: 102%;
      height: 78%;
      background: #fff;
      border-radius: 20%;
    }
  }
  span.left,
  span.right {
    width: 43%;
    height: 33%;
    &:after {
      width: 78%;
      height: 102%;
    }
  }

  span.up {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 17% 17% 50% 50%;
    &:hover {
      background: linear-gradient(0deg, #ddd 0%, #eee 50%);
    }
    &:after {
      left: 0;
      top: 0;
      transform: translate(-100%, 0);
      border-top-left-radius: 50%;
      pointer-events: none;
    }
    &:before {
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-width: 0 13px 19px 13px;
      border-color: transparent transparent #aaa transparent;
    }
    &:active:before {
      border-bottom-color: #333;
    }
  }
  span.up:hover:before {
    top: 35%;
  }

  span.down {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50% 50% 17% 17%;
    &:hover {
      background: linear-gradient(180deg, #ddd 0%, #eee 50%);
    }
    &:after {
      right: 0;
      bottom: 0;
      transform: translate(100%, 0);
      border-bottom-right-radius: 50%;
      pointer-events: none;
    }
    &:before {
      bottom: 40%;
      left: 50%;
      transform: translate(-50%, 50%);
      border-width: 19px 13px 0px 13px;
      border-color: #aaa transparent transparent transparent;
    }
    &:active:before {
      border-top-color: #333;
    }
  }
  span.down:hover:before {
    bottom: 35%;
  }

  span.left {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    border-radius: 17% 50% 50% 17%;
    &:hover {
      background: linear-gradient(-90deg, #ddd 0%, #eee 50%);
    }
    &:after {
      left: 0;
      bottom: 0;
      transform: translate(0, 100%);
      border-bottom-left-radius: 50%;
      pointer-events: none;
    }
    &:before {
      left: 40%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-width: 13px 19px 13px 0;
      border-color: transparent #aaa transparent transparent;
    }
    &:active:before {
      border-right-color: #333;
    }
  }
  span.left:hover:before {
    left: 35%;
  }

  span.right {
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    border-radius: 50% 17% 17% 50%;
    &:hover {
      background: linear-gradient(90deg, #ddd 0%, #eee 50%);
    }
    &:after {
      right: 0;
      top: 0;
      transform: translate(0, -100%);
      border-top-right-radius: 50%;
      pointer-events: none;
    }
    &:before {
      right: 40%;
      top: 50%;
      transform: translate(50%, -50%);
      border-width: 13px 0 13px 19px;
      border-color: transparent transparent transparent #aaa;
    }
    &:active:before {
      border-left-color: #333;
    }
  }
  span.right:hover:before {
    right: 35%;
  }

  &.up span.up:before {
    border-bottom-color: #333;
  }
  &.down span.down:before {
    border-top-color: #333;
  }
  &.left span.left:before {
    border-right-color: #333;
  }
  &.right span.right:before {
    border-left-color: #333;
  }
}

.Laser {
  font-family: 'Laser';
}
.Maven {
  font-family: 'Maven';
}
.Raleway {
  font-family: 'RalewayDots';
}

@media (min-height: 576px) {
  #viewerControls {
    top: 25px;
    ul {
      margin-top: 25px;
    }

    #rotateCube.cube .cube__face,
    #rotateCube.cube,
    #rotateCubeArrows {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }

    #rotateCube.cube {
      .cube__faceplate {
        height: 33px;
        width: 33px;
      }
      .cube__facefront {
        transform: rotateY(0deg) rotateX(180deg) translateZ(-25px);
      }
      .cube__faceright {
        transform: rotateY(90deg) rotateX(180deg) translateZ(-25px);
      }
      .cube__faceback {
        transform: rotateY(180deg) rotateX(180deg) translateZ(-25px);
      }
      .cube__faceleft {
        transform: rotateY(-90deg) rotateX(180deg) translateZ(-25px);
      }
      .cube__facetop {
        transform: rotateX(-90deg) rotateX(180deg) translateZ(-25px);
      }
      .cube__facebottom {
        transform: rotateX(90deg) rotateX(180deg) translateZ(-25px);
        font-size: 12px;
      }
      #rotateCubeShadow {
        transform: rotateX(90deg) rotateX(180deg) translateZ(-27px);
        width: 44px;
        height: 44px;
      }
    }
  }
}
