#loader {
  background-color: white;
  width: 100%;
  padding: 15px;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .progress,
  .progress .progressBar {
    overflow: visible;
    position: relative;
    border-radius: 10px;
  }

  .progressBarWrapper {
    display: flex;
    justify-content: flex-end;

    .progressValues {
      display: flex;
      justify-content: space-between;
      min-width: fit-content;

      .progressValue,
      .progressValueEnd {
        min-width: fit-content;
        font-size: 8pt;
        font-weight: 500;
        color: #04cc58;
        text-align: end;
      }
      .progressValueEnd {
        margin-left: 3px;
      }
    }
  }
  .progressBarInner > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #04cc58;
    transition: width 0.6s ease;
  }
  .yourProgress {
    color: #04cc58;
    background-color: white;
  }
  .progressHeader {
    display: inline-block;
    margin: 0;
  }
  .loadingDots {
    position: relative;
    display: inline-block;

    .loadingDot {
      position: absolute;
      width: 2px;
      height: 2px;
      border-radius: 5px;
      background-color: black;
      color: black;
      animation: dotFlashing 1s infinite linear alternate;
      bottom: 0px;
      left: 0px;

      &:nth-child(1) {
        left: 5px;
        animation-delay: 333ms;
      }
      &:nth-child(2) {
        left: 9px;
        animation-delay: 667ms;
      }

      @keyframes dotFlashing {
        0% {
          background-color: #000000;
        }
        50%,
        100% {
          background-color: #c4c4c4;
        }
      }
    }
  }
}
