#sidebarWrapper {
  position: absolute;
  top: 15px;
  bottom: 15px;
  right: 0;
  z-index: 3;
  width: auto;
  transition: 0.3s;

  #sidebar {
    height: 100%;
    background-image: linear-gradient(to right, rgba(white, 0.85), rgba(white, 0.95));
    width: 300px;
    border-radius: 15px 0 0 15px;
    box-shadow: -1px 1px 8px -3px #a3a3a3;
    border: 8px solid #04cc58;
    border-right-width: 0;
    margin-left: auto;
    transition: 0.3s;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;

    .header {
      align-items: center;
      font-size: x-large;
      font-weight: 600;
      text-align: center;
      padding: 10px 15px;
      border-bottom: 4px solid #04cc58;
    }

    ul {
      margin-top: 1rem;
    }

    #scrollable {
      overflow-y: auto;
    }
  }

  &.hidden {
    transition: 0.3s;

    #sidebar {
      transition: 0.3s;
      width: 0;
      border-left: 0;
      margin-left: 0;
    }
  }

  .sidebarHidden {
    position: absolute;
    right: 100%;
    bottom: 35px;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #04cc58;
      height: 40px;
      width: 40px;
      border-radius: 5px 0 0 5px;
      cursor: pointer;

      &.isActive {
        background-color: #04cc58;
      }
    }
  }
}
