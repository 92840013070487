// @import '/styles/customizables.scss';
$primary: green;

#aboutModal {
  position: relative;

  .closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }

  a {
    color: lighten($primary, 20%);
    text-decoration: none;
    background-color: transparent;
    transition: 0.3s;

    &:hover {
      text-decoration: underline;
      color: $primary;
    }
  }
}
