#drawer {
  height: 350px;
  background-color: #04cc58;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  box-shadow: -1px 0px 8px -3px #a3a3a3;
  z-index: 5;

  #loader {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;

    > div {
      color: rgb(13, 110, 253) !important;
    }
  }

  .slide {
    position: relative;
    min-width: 300px;
    max-width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px;
    transition: 0.3s;

    button {
      min-height: 32px;
      color: white;
    }

    &:hover {
      cursor: move;
    }

    &.isActive {
      min-width: 50%;

      .overlay {
        width: 100%;
        height: 100%;
        justify-content: space-between;

        .buttons {
          width: 100%;
          text-align: end;

          .center {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .left {
            transform: rotate(180deg);
          }
        }
        .description {
          overflow: auto;

          display: -webkit-box;
          -webkit-line-clamp: unset;
          -webkit-box-orient: vertical;
        }
      }
    }

    .overlay {
      z-index: 2;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;
      width: 100%;
      height: 100%;
      background-color: white;
      padding: 15px;
      border-radius: 15px;

      .title {
        color: black;
        text-align: center;
        font-weight: 600;
      }
      .description {
        overflow: hidden;
        margin: 0 0 1em 0;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }

  &.scroll {
    height: 230px;
  }
  .modelBlock {
    position: relative;
    background-color: white;
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 230px;

    &:not(:last-child) {
      border-right: 1px solid lightgrey;
    }
    &:hover {
      background-color: #e8e8e8;

      .overlay {
        display: flex;
      }
    }

    .overlay {
      display: none;
      padding: 15px;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      button {
        min-width: 50%;
      }
    }
  }

  &:not(.scroll)::-webkit-scrollbar {
    height: 0px;
  }
  &:hover {
    cursor: e-resize;
  }
}
