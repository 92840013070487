#logoMenu {
  z-index: 5;
  height: 38px;
  width: 160px;
  background: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  align-content: center;
  box-shadow: 1px 1px 8px -3px #232323;
  position: absolute;
  top: 8px;
  left: 8px;

  #logo {
    width: 100%;
    height: 100%;
    background-image: url('../img/logo2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 0 8px;
  }
  #logoDivider {
    width: 1px;
    height: 100%;
    position: relative;

    &::after {
      background-color: #95c2a8;
      opacity: 0.3;
      content: '';
      display: block;
      width: 1px;
      height: 65%;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }
  #hamburger {
    display: flex;
    align-items: center;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    height: 100%;

    a {
      text-decoration: none;
      color: #232323;

      transition: color 0.3s ease;
    }

    input {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;

      cursor: pointer;

      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */

      -webkit-touch-callout: none;

      ~ ul {
        background-color: #eee;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 0.26s ease;
      }
      &:checked {
        ~ #hamburgerWrapper span {
          opacity: 1;
          transform: rotate(-45deg) translate(0px, 2px);
          width: 19px;

          &:nth-last-child(2) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
          }
          &:nth-last-child(3) {
            transform: rotate(45deg) translate(2px, -2px);
          }
        }
        ~ ul {
          transform: scaleY(1);
        }
      }
    }

    #hamburgerWrapper {
      padding-top: 4px;

      span {
        display: block;
        width: 18px;
        height: 2px;
        margin-bottom: 4px;
        position: relative;

        background: #04cc58;
        border-radius: 3px;

        z-index: 1;

        transform-origin: 0px 0px;

        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          opacity 0.55s ease;

        &:first-child {
          transform-origin: 0% 0%;
        }
        &:nth-last-child(2) {
          transform-origin: 0% 100%;
        }
      }
    }

    #menu {
      position: fixed;
      width: 195px;
      max-height: calc(100% - 80px);
      left: 8px;
      top: 55px;
      padding: 7.5px 15px;
      z-index: 9;
      border-radius: 5px;
      overflow-y: auto;

      background: white;
      list-style-type: none;
      -webkit-font-smoothing: antialiased; /* to stop flickering of text in safari */
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);

      li {
        font-size: 15px;
        font-weight: 600;
        line-height: 14pt;
        padding: 7px 0;

        a:hover {
          color: #04cc58;
        }
      }

      #XR_title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .withBadge {
          display: flex;
          align-items: flex-start;

          > span {
            background-color: lighten(#04cc58, 20%) !important;
          }
        }
        #VRINFOIMG {
          cursor: help;
        }
        h5 {
          font-weight: bold;
        }
        small.badge {
          margin-left: 5px;
        }
      }

      #partyCode {
        color: grey;
      }
    }
  }
}
